



















































































































































































































































































































































import Base from '@/mixins/Base.vue';

import { ISource } from '@/interfaces/source';
import { IClientsResponse, IClient } from '@/interfaces/client';
import { IClientType } from '@/interfaces/clienttype';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    data() {
        return {
            params: {
                client_type: {} as IClientType,
                active: null,
                source: {} as ISource,
                name: '',
                sortBy: 'name',
                orderBy: 'asc',
                page: 1,
            },

            clients: {
                data: [] as Array<IClient>,
                meta: {} as IPagination | any,
            } as IClientsResponse,
        };
    },

    updated() {
    },
    mounted() {
        this.getClients();
    },
    methods: {
        getClients(page: number = 1): void {
            this.params.page = page;
            this.get<IClientsResponse>('clients', {
                params: {
                    ...this.params,
                    source_id: this.params.source ? this.params.source.id : null,
                    client_type_id: this.params.client_type ? this.params.client_type.id : null,
                },
            }).then(({ data }) => {
                this.clients = data;
            });
        },
    },

});

export default component;
